@use './base';

.custom-dashboard-container {
  height: 100%;
}

// this was used to center the <Header> component
// .dashboard-resource-header {
//   align-items: center;
//   flex-direction: column;
// }
